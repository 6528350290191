import React from "react";
import {
  StyledAdoptStatistics,
  StyledItemsScroller,
  StyledItem,
  StyledItemDescription,
  StyledItemImage,
} from "./style";
import { StyledGridRow } from "../../commons/Grid";
import Scroller from "../Scroller/Scroller";

export default ({ items, noMargin = false }) => {

  return (
    <StyledAdoptStatistics>
      <StyledGridRow isFullMobile noMargin={noMargin}>
        <Scroller>
          <StyledItemsScroller>
            {items.map((item, index) => (
              <StyledItem key={index} >
                <StyledItemImage bg={item.imageSrc} />
                <StyledItemDescription
                  dangerouslySetInnerHTML={{ __html: item.caption }}
                />
              </StyledItem>
            ))}
          </StyledItemsScroller>
        </Scroller>
      </StyledGridRow>
    </StyledAdoptStatistics>
  );
};
